<template>
  <div class="statistic-block">
    <div>
      <FormItemTooltipLabel v-if="hint" :label="title" :tooltipWidth="200" class="title">
        {{ hint }}
      </FormItemTooltipLabel>
      <div v-if="!hint" class="title">{{ title }}</div>
      <div class="value">{{ value }}</div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
export default defineComponent({
  name: 'StatisticBlock',
  components: { FormItemTooltipLabel },
  props: ['title', 'value', 'hint'],
  setup (props) {
    return {}
  },
})
</script>

<style lang="postcss" scoped>
.statistic-block {
  @apply flex justify-center items-center flex-1;
  @apply bg-[#fff];
  @apply h-[120px];
  @apply text-center;
  @apply rounded;
}

.title {
  @apply text-gray-80;
  @apply pb-2;
}

.value {
  @apply text-primary-100 text-[28px];
}
</style>
