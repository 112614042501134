<template>
  <div class="reservation-statistics">
    <ChartTitle
      title="預約 首購 vs 回購人數趨勢"
      hint="預約首購客人數：第一次消費且過去沒有任何預約消費紀錄者，預約回購客人數：過去365天內有1次以上的預約消費紀錄者"
      :useRangeType="true"
      :getChart="getChart"
    />
    <div ref="chartRef" class="chart" />
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import ChartTitle from '@/components/Dashboard/ChartTitle.vue'
import store from '@/store'
import { useChart } from '@/use/dashboard'
import { generateFirstPurchaseConfig, getFirstPurchaseData } from '../../common'

export default defineComponent({
  name: 'ReservationStatistics',
  components: { ChartTitle },
  setup () {
    const { chartRef, updateChart } = useChart(generateFirstPurchaseConfig())
    const shopId = computed(() => store.getters.shop)

    const getChart = async ({ interval, splitType }) => {
      const newOptions = await getFirstPurchaseData({
        shopId: shopId.value,
        type: '預約訂單',
        interval,
        splitType,
      })

      updateChart(newOptions)
    }

    return {
      chartRef,
      getChart,
    }
  },
})
</script>

<style lang="postcss" scoped>
.reservation-statistics {
  @apply flex flex-col gap-5;
}
</style>
