<template>
  <div class="operate-overview">
    <PageTitle title="會員數據總覽" hideBtn />
    <div class="updateAt">
      <p>{{ updateAt }}</p>
      <p>{{ updateTime }}</p>
    </div>
    <section class="flex flex-col gap-10">
      <OverviewBlock @getTime="getTime" />
      <MemberCountStatistics class="chart-block" />
      <ReservationStatistics class="chart-block" />
      <EcommerceStatistics class="chart-block" />
      <SalesStatistics class="chart-block" />
      <MemberShopStatistics class="chart-block" />
      <ConsumptionStatistics class="chart-block" />
    </section>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import dayjs from 'dayjs'
import OverviewBlock from './components/OverviewBlock/OverviewBlock.vue'
import MemberCountStatistics from './components/MemberCountStatistics/MemberCountStatistics.vue'
import ReservationStatistics from './components/ReservationStatistics/ReservationStatistics.vue'
import EcommerceStatistics from './components/EcommerceStatistics/EcommerceStatistics.vue'
import SalesStatistics from './components/SalesStatistics/SalesStatistics.vue'
import MemberShopStatistics from './components/MemberShopStatistics/MemberShopStatistics.vue'
import ConsumptionStatistics from './components/ConsumptionStatistics/ConsumptionStatistics.vue'

export default defineComponent({
  name: 'OperateOverview',
  components: {
    OverviewBlock,
    MemberCountStatistics,
    ReservationStatistics,
    EcommerceStatistics,
    SalesStatistics,
    MemberShopStatistics,
    ConsumptionStatistics,
  },
  setup () {
    const time = ref(null)

    const updateAt = computed(() => {
      return `數據截止日：${
        time.value ? dayjs(time.value).format('YYYY/MM/DD') : '-'
      }`
    })
    const updateTime = computed(() => {
      return `數據更新時間：${
        time.value ? dayjs(time.value).format('YYYY/MM/DD HH:mm:ss') : '-'
      }`
    })
    const getTime = (e) => {
      time.value = e
    }
    return {
      getTime,
      updateAt,
      updateTime,
    }
  },
})
</script>

<style lang="postcss" scoped>
.updateAt {
  @apply flex justify-end gap-[20px];
  @apply text-[#767676];
  @apply pb-[34px];
}
.chart-block {
  @apply bg-[#fff];
  @apply p-5;
  @apply rounded;
}
</style>
